<template>
  <div id="app" class="app-background" style="height: 100vh;overflow-x: hidden;flex-direction: column;">
    <el-backtop target=".app-background" :bottom="100">
      <!-- <div
        style="{
          height: 40px;
          width: 40px;
          text-align: center;
        }"
      > -->
      <img style="width: 40px;height: 40px;" alt="" :src="require('@/assets/image/回到最顶端按钮永远右@2x.png')" />
      <!-- </div> -->
    </el-backtop>
    <div v-if="!mobile_flag" class="mj-top" id="app-choose" :style="'background-image:' + topBackgroundImg + ';'">
      <img class="mj-top_img" :style="mobile_flag ? 'width: 100%;' : 'width: 100%;'"
        :src="require('@/assets/background/导航栏遮罩@2x.png')" alt="" />
      <div class="mj-top-image">
        <img src="./assets/logo/logo.png" alt="图片显示异常">
      </div>
      <!-- <div class="mj-top-right">
        <div class="mj-top-right-list" v-for="(item,index) in topList" :key="index" @click="goDetail(item.value)">
          <div class="mj-top-right-item" :class="headerActiveIndex == item.value ? 'mj-top-right-item-choose' : 'mj-top-right-item-no-choose'">{{ item.label }}</div>
          <div class="mj-top-right-item-solid" v-if="index < (topList.length - 1)"></div>
        </div>
      </div> -->
      <div class="mj-top-right">
        <div class="mj-top-right-list">
          <div class="mj-top-right-item"
            :style="mobile_flag ? (isiOS ? 'padding-top: 0px;font-size: 25px;' : 'padding-top: 0px;font-size: 25px;') : ''"
            @click="goDetail(1)"
            :class="headerActiveIndex == 1 ? 'mj-top-right-item-choose' : 'mj-top-right-item-no-choose'">官网首页</div>
          <div class="mj-top-right-item-solid"></div>
          <!-- <div class="mj-top-right-item" @click="goDetail(2)" :class="headerActiveIndex == 2 ? 'mj-top-right-item-choose' : 'mj-top-right-item-no-choose'">业务体系</div> -->
          <div class="mj-top-right-item"
            :style="mobile_flag ? (isiOS ? 'padding-top: 0px;font-size: 25px;' : 'padding-top: 0px;font-size: 25px;') : ''"
            style="position: relative;" @click="showWindow(true)" @mouseleave="showWindow(false)"
            @mouseover="showWindow(true)"
            :class="headerActiveIndex == 2 ? 'mj-top-right-item-choose' : 'mj-top-right-item-no-choose'">
            <div class="mj-top-right-item-text">业务体系</div>
            <div class="mj-top-right-item-window" v-if="showWindowType"
              :style="'background-image:' + topChooseBackgroundImg.backgroundImage + ';'">
              <div class="mj-top-right-item-name"
                :class="showWindowPage == 2 ? 'mj-top-right-item-name-choose' : 'mj-top-right-item-name-no-choose'"
                @click="goPage('zhengjing')">牧鲸数字</div>
              <div class="mj-top-right-item-name"
                :class="showWindowPage == 3 ? 'mj-top-right-item-name-choose' : 'mj-top-right-item-name-no-choose'"
                @click="goPage('yihuo')">牧鲸易货</div>
              <div class="mj-top-right-item-name"
                :class="showWindowPage == 4 ? 'mj-top-right-item-name-choose' : 'mj-top-right-item-name-no-choose'"
                @click="goPage('ziben')">牧鲸资本</div>
              <div class="mj-top-right-item-name"
                :class="showWindowPage == 5 ? 'mj-top-right-item-name-choose' : 'mj-top-right-item-name-no-choose'"
                @click="goPage('gongyi')">牧鲸公益</div>
            </div>
          </div>
          <div class="mj-top-right-item-solid"></div>
          <div class="mj-top-right-item"
            :style="mobile_flag ? (isiOS ? 'padding-top: 0px;font-size: 25px;' : 'padding-top: 0px;font-size: 25px;') : ''"
            @click="goDetail(3)"
            :class="headerActiveIndex == 3 ? 'mj-top-right-item-choose' : 'mj-top-right-item-no-choose'">客户案例</div>
          <div class="mj-top-right-item-solid"></div>
          <div class="mj-top-right-item"
            :style="mobile_flag ? (isiOS ? 'padding-top: 0px;font-size: 25px;' : 'padding-top: 0px;font-size: 25px;') : ''"
            @click="goDetail(4)"
            :class="headerActiveIndex == 4 ? 'mj-top-right-item-choose' : 'mj-top-right-item-no-choose'">关于我们</div>
          <div class="mj-top-right-item-solid"></div>
          <div class="mj-top-right-item"
            :style="mobile_flag ? (isiOS ? 'padding-top: 0px;font-size: 25px;' : 'padding-top: 0px;font-size: 25px;') : ''"
            @click="goDetail(5)"
            :class="headerActiveIndex == 5 ? 'mj-top-right-item-choose' : 'mj-top-right-item-no-choose'">联系我们</div>
        </div>
      </div>
    </div>

    <div v-else class="mj-top-h5" ref="topH5Ref">
      <div class="mj-top-h5-logo">
        <img src="./assets/logo/logo.png" alt="logo" />
      </div>
      <div class="mj-top-h5-menu">
        <!-- mj-top-h5-menu-item-active -->
        <div @click="goPageH5(top)" :class="[ 'mj-top-h5-menu-item',]" v-for="(top, index) in topList" :key="top.value">
          <span
            :class="[index !== topList.length - 1 ? 'mj-top-h5-menu-item-bor' : '', currentTopIndex == index ? 'mj-top-h5-menu-item-active' : '' ]">{{
            top.label }}</span>
          <ul v-if="top.hasOwnProperty('children') && top.children.length > 0 && top.childrenShown"
            class="mj-top-h5-menu-item-children">
            <li @click="goPageH5(child)" v-for="(child) in top.children" :key="child.value"
              :class="[$route.path === child.url ? 'mj-top-h5-menu-item-active' : '' ]">{{ child.label }}</li>
          </ul>
        </div>
      </div>
    </div>

    <router-view />

    <footer style="background-color: #6A6A6A;width: 100%;height:auto;float: bottom;margin:0 auto;max-width: 100%">
      <div
        :style="'max-width:1200px;height: ' + (mobile_flag ? 415 : 470) + 'px;margin: 0 auto;width: ' + screenWidth + 'px;background-image: ' + footBackgroundImg + ';'">
        <div class="footer-show">
          <div v-if="!mobile_flag" class="footer-show-web">
            <div class="footer-show-logo">
              <div class="footer-show-logo-img">
                <img alt="" :src="require('@/assets/logo/logo.png')" style="height: 28px;width: 96px;"
                  @click="goDetail(1)" />
              </div>
              <div class="footer-show-logo-img">
                <img alt="" :src="require('@/assets/image/正鲸@2x.png')" style="height: 27px;width: 90px;"
                  @click="goPage('zhengjing')" />
              </div>
              <div class="footer-show-logo-img">
                <img alt="" :src="require('@/assets/image/易货@2x.png')" style="height: 26px;width: 69px;"
                  @click="goPage('yihuo')" />
              </div>
              <div class="footer-show-logo-img">
                <img alt="" :src="require('@/assets/image/资本@2x.png')" style="height: 26px;width: 84px;"
                  @click="goPage('ziben')" />
              </div>
              <div class="footer-show-logo-img">
                <img alt="" :src="require('@/assets/image/公益@2x.png')" style="height: 27px;width: 52px;"
                  @click="goPage('gongyi')" />
              </div>
              <!-- <div class="footer-show-logo-img">
                <img alt="" :src="require('@/assets/image/cica@2x.png')" style="height: 27px;width: 25px;" />
              </div> -->
            </div>
            <div class="footer-show-text-value">
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 15px; height: 13px;" :src="require('@/assets/image/邮件_mail@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                  邮箱：alex@mejour.cn</div>
              </div>
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 17px; height: 19px;" :src="require('@/assets/image/电话@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">电话：051983339625
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">手机：15861176553
                </div>
              </div>
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                  地址：上海市普陀区曹杨路619号（曹杨路地铁站4号口步行60米 近铁云中心）9楼整层</div>
              </div>
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                  常州分公司地址：常州市武进区天安数码城B-1幢1701室</div>
              </div>
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                  重庆分公司地址：重庆市渝中区大坪正街19号英利国际1号楼25-22室</div>
              </div>
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                  广州分公司地址：广州市天河区华强路9号中盈大厦1107室</div>
              </div>
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                  杭州分公司地址：杭州市拱墅区越都商务大厦608室</div>
              </div>
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                  北京分公司地址：北京市朝阳区万达广场8号楼809室</div>
              </div>
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                  深圳分公司地址：广东省深圳市福田区泰然五路天展大厦AB座5A02</div>
              </div>
              <div class="footer-show-text-msg">
                <div>
                  <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
                </div>
                <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                  成都分公司地址：成都市锦江区走马街55号友谊广场B座1713室</div>
              </div>
            </div>
            <div class="footer-show-right">
              <div>
                <img style="width: 198px; height: 198px;" :src="require('@/assets/image/二维码@2x.png')" alt="" />
              </div>
            </div>
          </div>
          <div class="footer-show-text-detail-mobile-left" v-if="mobile_flag">
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 15px; height: 13px;" :src="require('@/assets/image/邮件_mail@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">邮箱：alex@mejour.cn
              </div>
            </div>
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 17px; height: 19px;" :src="require('@/assets/image/电话@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">电话：051983339625
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">手机：15861176553
              </div>
            </div>
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                地址：上海市普陀区曹杨路619号（曹杨路地铁站4号口步行60米 近铁云中心）9楼整层</div>
            </div>
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                常州分公司地址：常州市武进区天安数码城B-1幢1701室</div>
            </div>
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                重庆分公司地址：重庆市渝中区大坪正街19号英利国际1号楼25-22室</div>
            </div>
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                广州分公司地址：广州市天河区华强路9号中盈大厦1107室</div>
            </div>
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                杭州分公司地址：杭州市拱墅区越都商务大厦608室</div>
            </div>
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                北京分公司地址：北京市朝阳区万达广场8号楼809室</div>
            </div>
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                深圳分公司地址：广东省深圳市福田区泰然五路天展大厦AB座5A02</div>
            </div>
            <div class="footer-show-text-msg">
              <div>
                <img style="width: 18px; height: 18px;" :src="require('@/assets/image/已定位_local@2x.png')" alt="" />
              </div>
              <div :class="mobile_flag ? 'footer-show-text-content-size' : 'footer-show-text-content'">
                成都分公司地址：成都市锦江区走马街55号友谊广场B座1713室</div>
            </div>
          </div>
          <div class="footer-show-text-detail-mobile" v-if="mobile_flag">
            <div class="footer-show-right-mobile-img">
              <img style="height: 25px;" :src="require('@/assets/logo/logo.png')" alt="" @click="goDetail(1)" />
            </div>
            <div class="footer-show-right-mobile-img">
              <img style="height: 20px;" :src="require('@/assets/image/正鲸@2x.png')" alt=""
                @click="goPage('zhengjing')" />
            </div>
            <div class="footer-show-right-mobile-img">
              <img style="height: 20px;" :src="require('@/assets/image/易货@2x.png')" alt="" @click="goPage('yihuo')" />
            </div>
            <div class="footer-show-right-mobile-img">
              <img style="height: 20px;" :src="require('@/assets/image/资本@2x.png')" alt="" @click="goPage('ziben')" />
            </div>
            <div class="footer-show-right-mobile-img">
              <img style="height: 20px;" :src="require('@/assets/image/公益@2x.png')" alt="" @click="goPage('gongyi')" />
            </div>
            <!-- <div class="footer-show-right-mobile-img">
              <img style="height: 20px;" :src="require('@/assets/image/cica@2x.png')" alt="" />
            </div> -->
            <div class="footer-show-right-mobile-img">
              <img style="width: 98px; height: 98px;" :src="require('@/assets/image/二维码@2x.png')" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="mj-bottom" style="width: 100%; font-size: 0.2rem;transform: translateY(50%);padding-top: 0.2rem;"
        :style="mobile_flag ? 'height: 50px;padding:10px 0;' : ''">
        <!-- <div>
          <img style="width: 100%;height: 50px;" :src="require('@/assets/image/最底部.png')" alt="" />
        </div> -->
        <div class="mj-bottom-title">©2022 牧鲸科技 All Right Reserved</div>
        <div class="mj-bottom-msg"><img src="./assets/old/gongan.png" alt="">
          <a class="gonganlianjie" target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=000000000000&token=8f724cb8-ae79-4036-a548-99e12f1f0623">苏公网安备32041202002126号</a>

          <a class="gonganlianjie" target="_blank" href="https://beian.miit.gov.cn">苏ICP备18040928号-5</a>
        </div>
      </div>
      <!-- <div
        style="width: 100%; height:50px;text-align:center;background-color: #1f1e1e;font-size: 0.2rem;transform: translateY(50%);padding-top: 0.2rem">
        <div>©2022 牧鲸科技 All Right Reserved</div>
        <div><img src="./assets/old/gongan.png" alt="">
          <a class="gonganlianjie" target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=000000000000&token=8f724cb8-ae79-4036-a548-99e12f1f0623">苏公网安备00000000000000</a>
          &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
          <a class="gonganlianjie" target="_blank" href="https://beian.miit.gov.cn">苏ICP备18040928号-5</a>
        </div>
      </div> -->
    </footer>

  </div>
</template>

<style>
.mj-top-h5 {
  width: 100%;
  height: 3rem;
  /* padding: 4rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  position: relative;
  background-color: rgba(37, 47, 41, .5);
}

.mj-top-h5-logo {
  width: 20%;
}

.mj-top-h5-logo img {
  width: 100%;
  height: 100%;
}
.mj-top-h5-menu {
  width: 80%;
  font-size: .6rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #FFFFFF;
}
.mj-top-h5-menu-item {
  width: 100%;
  text-align: center;
  position: relative;
}
.mj-top-h5-menu-item>span {
  width: 100%;
  display: block;
}
.mj-top-h5-menu-item-bor {
  border-right: 1px solid #FFFFFF;
}
.mj-top-h5-menu-item-active {
  color: #F8B800;
}
.mj-top-h5-menu-item-children {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  background-color: rgba(37, 47, 41, .5);
  transform: translateY(10%);
}
.mj-top-h5-menu-item-children li {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  list-style: none;
}

.mj-top-right-item-name-choose{
  color: #F8B800;
}
.mj-top-right-item-name-no-choose{
  color: #FFFFFF;
}
.mj-top-right-item-window{
  position: fixed;
  width: 161px;
  height: auto;
  color: #FFFFFF;
  margin: 10px 0;
  padding-bottom: 20px;
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
  background-color: #fdf5dd;
  color: #F8B800;
}
.el-popper .popper__arrow{
  border-bottom-color: #2B2B2B !important;
}
.el-popper .popper__arrow::after{
  border-bottom-color: #2B2B2B !important;
}
.el-dropdown-menu{
  background-color: #2B2B2B !important;
  border: 1px solid #2b2b2b !important;
}
.el-backtop{
  background-color: transparent !important;
}
.app-background {
  background-color: #13161B;
}

.mj-top {
  height: 171px;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 900;
  display: inline-flex;
}
.mj-top-image{
  position: absolute;
}
.mj-top-image img {
  height: 57px;
  width: 202px;
  position: absolute;
  padding-top: 56px;
  padding-left: 118px;
}
.mj-top-right{
  position: absolute;
  right: 0;
  display: inline-flex;
  padding-top: 66px;
  padding-right: 81px;
}
.mj-top-right-list{
  display: inline-flex;
}
.mj-top-right-item-1{
  width: 161px;
  padding: 13px 0 14px 0;
  text-align: center;
  font-family: AlibabaPuHuiTiR;
  font-size: 19px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}
.mj-top-right-item-name{
  margin: 25px auto;
}
.mj-top-right-item-name:hover{
  color: #F8B800;
}
.mj-top-right-item-text:hover{
  color: #F8B800;
}
.mj-top-right-item{
  width: 161px;
  padding: 0px 0 14px 0;
  text-align: center;
  font-family: AlibabaPuHuiTiR;
  font-size: 19px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  line-height: 45px;
  margin: 0 auto;
}
.mj-top-right-item:hover{
  color: #F8B800;
}
.mj-top-right-item-solid{
  border-right: 2px solid white;
  height: 45px;
}
.mj-top-right-item-no-choose{
  color: #FFFFFF;
}
.mj-top-right-item-choose{
  color: #F8B800;
}
</style>
<style>
.el-menu--horizontal>.el-menu-item {
  height: 51px !important;
}

.el-menu .el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}

.footthree {
  float: left;
  width: 33.33%;
  height: 100%;
  overflow: hidden;
}

.footthree div a {
  text-decoration: none;
  color: #d0cccc;
}

.gonganlianjie {
  text-decoration: none;
  color: #8d8d8d;
}

.quicknav {
  margin: 18px auto;
  font-size: 0.9rem;
}

#app {
  height: calc(100% - 45px);
  max-width: 1903rpx;
  z-index: 800;
}

footer {
  color: white;
}

.ct {}

.ct li {
  height: 30px;
  line-height: 30px;
}

.ct li span {
  height: 30px;
  line-height: 30px;
}

.footericon {
  max-width: 25px;
  max-height: 25px;
  /* margin-left: 30px; */
}

.erweima {
  max-width: 100px;
  max-height: 100px;
}

.el-menu-item.is-active {
  color: #18909B !important;
}

.el-menu-item {
  color: blue;
}

.footer-show {
  display: flex;
  position: relative;
  height: 100%;
  font-size: 17px;
}

.footer-show-text {
  color: white;
  position: absolute;
  top: 50%;
}

.footer-show-text-title {
  display: flex;
}

.footer-show-text-name {}

.footer-show-text-slogan {
  padding-left: 10px;
}

.footer-show-text-contact {
  display: flex;
}

.footer-show-text-mail {
  padding-left: 20px;
}

.footer-show-text-address {
  padding-left: 20px;
}

.footer-show-text-contact-mobile {}

.footer-show-text-mobile {
  width: 50%;
  margin: 20px;
  height: 100%;
}

.footer-show-text-slogan-mobile {
  padding-top: 5px;
}

.footer-show-text-contact-mobile {
  padding-top: 10px;
}

.footer-show-text-tel-mobile {
  padding-top: 5px;
}

.footer-show-text-mail-mobile {
  padding-top: 5px;
}

.footer-show-text-address-mobile {
  padding-top: 5px;
}

.footer-show-text-detail {
  position: absolute;
  right: 0;
}

.footer-show-text-detail-mobile-left {
  width: 50%;
  margin: 20px;
  height: 100%;
  position: absolute;
  left: 0;
}
.footer-show-text-detail-mobile {
  width: 30%;
  margin: 20px;
  height: 100%;
  position: absolute;
  right: 0;
}
.footer-show-right-mobile-img{
  padding-top: 10px;
}

.footer-show-text-go-detail {
  margin-top: 20px;
}

.footer-show-text-detail {
  cursor: pointer;
  display: flex;
}

.footer-show-text-detail-item {
  margin: 0 10px;
}
.footer-show-logo{
  display: inline-flex;
  padding-top: 59px;
}
.footer-show-logo-img img{
  cursor: pointer;
}
.footer-show-logo-img{
  border-left: 1px solid #FFFFFF;
  padding-left: 20px;
  padding-right: 20px;
  border-image: linear-gradient(0deg, #7C7C7C, #FAFAFA 50%, #7C7C7C) 1;
}
.footer-show-logo-img:first-child{
  border-left: 0px solid #FFFFFF;
  padding-left: 0px;
}
.footer-show-text-value{
  padding-top: 40px;
  position: absolute;
}
.footer-show-text-msg{
  display: flex;
  padding-top: 14px;
}
.footer-show-text-msg:first-child{
  padding-top: 0px;
}
.footer-show-text-content{
  font-size: 14px;
  font-family: AlibabaPuHuiTiL;
  font-weight: 300;
  color: #FFFFFF;
  padding-left: 10px;
  margin: 1px auto;
  width: 100%;
}
.footer-show-text-content-size{
  font-size: 9px;
  font-family: AlibabaPuHuiTiL;
  font-weight: 300;
  color: #FFFFFF;
  padding-left: 10px;
  margin: 1px auto;
  width: 100%;
}
.footer-show-right{
  position: absolute;
  right: 0;
  padding-top: 50px;
}
.mj-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .3125rem;
  height: 4.375rem;
  background-color: #222;
  box-sizing: border-box;
}

.mj-bottom-title {
  font-size: .6rem;
  color: #8d8d8d;
}
.mj-bottom-msg {
  font-size: .6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>
<script>
import ElementToLeaveObserverInstance from '@/utils/elementToLeaveObserver';

export default {
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      mobile_flag: false,
      screenWidth: 1200,
      screenWidthFootLogo: 100,
      screenWidthFootSize: 70,
      screenWidthFootIconLeft: 20,
      screenWidthFootIconLeftStart: 40,
      screenWidthFootIconLeftImg: 25,
      screenWidthFootRightCode: 100,
      screenWidthFootRightCodeLeft: 50,
      topList: [
        {
          label: "官网首页",
          value: 1,
          url: "/"
        },
        {
          label: "业务体系",
          value: 2,
          url: "",
          childrenShown: false,
          children: [
            { label: "牧鲸数字", value: 6, url: "/zhengjing"},
            { label: "牧鲸易货", value: 7, url: "/yihuo"},
            { label: "牧鲸资本", value: 8, url: "/ziben"},
            { label: "牧鲸公益", value: 9, url: "/gongyi"},
        ]
        },
        {
          label: "客户案例",
          value: 3,
          url: "/companyIntroduce"
        },
        {
          label: "关于我们",
          value: 4,
          url: "/contact"
        },
        {
          label: "联系我们",
          value: 5,
          url: "/recruit"
        },
      ],
      topBackgroundImg: require('@/assets/background/导航栏遮罩@2x.png'),
      footBackgroundImg: require('@/assets/background/底部.png'),
      headerActiveIndex: 1,
      showWindowType: false,
      showWindowPage: 1,
      topChooseBackgroundImg: { backgroundImage: "url(" + require("@/assets/image/首页标题下拉@2x.png") + ")" },
      isiOS: false,
    };
  },
  computed: {
    currentTopIndex() {
      const index = this.topList.findIndex(top => {
        if (top.hasOwnProperty('children') && top.children.length > 0) {
          return top.children.findIndex(top2 => {
            return top2.url === this.$route.path;
          }) > -1;
        } else {
          return top.url === this.$route.path;
        }
      }) 
      return index;
    },

  },
  mounted() {
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    this.isiOS = isiOS
    var a = location.href;
    if(a.indexOf('companyIntroduce') > -1){
      this.activeIndex = '3'
      this.headerActiveIndex = 3
      this.showWindowPage = 1
    } else if(a.indexOf('businessIntroduce') > -1){
      this.activeIndex = '2'
      this.headerActiveIndex = 2
      this.showWindowPage = 1
    } else if(a.indexOf('contact') > -1){
      this.activeIndex = '4'
      this.headerActiveIndex = 4
      this.showWindowPage = 1
    } else if(a.indexOf('recruit') > -1){
      this.activeIndex = '5'
      this.headerActiveIndex = 5
      this.showWindowPage = 1
    } else if(a.indexOf('zhengjing') > -1){
      this.activeIndex = '2'
      this.headerActiveIndex = 2
      this.showWindowPage = 2
    } else if(a.indexOf('yihuo') > -1){
      this.activeIndex = '2'
      this.headerActiveIndex = 2
      this.showWindowPage = 3
    } else if(a.indexOf('ziben') > -1){
      this.activeIndex = '2'
      this.headerActiveIndex = 2
      this.showWindowPage = 4
    } else if(a.indexOf('gongyi') > -1){
      this.activeIndex = '2'
      this.headerActiveIndex = 2
      this.showWindowPage = 5
    } else if (a.indexOf('viewMsg') > -1) {
      this.activeIndex = '3'
      this.headerActiveIndex = 3
      this.showWindowPage = 1
    }
    this.isMobile()
  },
  methods: {
    goPageH5(top) {
      if (top.hasOwnProperty('children') && top.children.length > 0) {
        top.childrenShown = !top.childrenShown;
        return;
      } 
      window.location.href = top.url
      this.handleChildMenu()
    },
    handleChildMenu() {
      this.topList.forEach(top => {
        if (top.hasOwnProperty('children') && top.children.length > 0) {
          top.childrenShown = false;
        } 
      })
    },
    goPage(val){
      let href = "/" + val
      window.location.href = href
      this.showWindowType = false
    },
    showWindow(type){
      this.showWindowType = type
    },
    // 跳转页面
    goDetail(type){
      this.headerActiveIndex = type
      // 1、官网首页，2、业务体系，3、客户案例，4、关于我们，5、联系我们
      let href = '/'
      if(type == 1){
        href = '/'
      } else if (type == 3) {
        href = '/companyIntroduce'
      } else if (type == 2) {
        href = '/businessIntroduce/jingyihudong'
      } else if (type == 4) {
        href = '/contact'
      } else if (type == 5) {
        href = '/recruit'
      } else {
        href = '/'
        this.headerActiveIndex = 1
      }
      window.location.href = href
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    isMobile() {
      var userAgentInfo = navigator.userAgent;
      var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      var mobile_flag = false;
      //根据userAgent判断是否是手机
        for (var v = 0; v < mobileAgents.length; v++) {
          if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                mobile_flag = true;
                break;
          }
        }
        var screen_width = window.screen.width;
        var screen_height = window.screen.height;
        this.screenWidth = screen_width;
        this.screenWidthFootLogo = 100/1200 * screen_width
        this.screenWidthFootSize = 70/1200 * screen_width
        this.screenWidthFootIconLeft = 20/1200 * screen_width
        this.screenWidthFootIconLeftStart = 40/1200 * screen_width
        this.screenWidthFootIconLeftImg = 25/1200 * screen_width
        this.screenWidthFootRightCode = 200/1200 * screen_width
        this.screenWidthFootRightCodeLeft = 50/1200 * screen_width
      //根据屏幕分辨率判断是否是手机
      if (screen_width > 325 && screen_height < 750) {
          mobile_flag = true;
      }
      this.mobile_flag = mobile_flag
      if(mobile_flag){
        // document.getElementById('app-choose').style.zoom = "0.25"
        this.$nextTick(() => {
          ElementToLeaveObserverInstance.init(this.$refs.topH5Ref, this.handleChildMenu)
        })
      }
    },
  },
  beforeDestroy() {
    if (this.mobile_flag) {
      ElementToLeaveObserverInstance.disconnect()
    }
  }
}
</script>
